import {useEffect, useState} from "react";
import {text} from "../util/constants";

export default function QuotationsViewBySites({state}) {


    const [selectedRow, setSelectedRow] = useState(null)
    const getBeautifulAmount = (value) => {
        let val = value
        val =  new Intl.NumberFormat('fr-FR')?.format(val)
        return val
    }

    useEffect(() => {}, [state]);

    return (
        <div style={{display: "grid", width: "94vw", rowGap: ".5rem"}}>

            <div style={{
                display: "inline-grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                width: "100%",
                justifyItems: "center",
                padding: ".5rem"
            }}>
                <div>
                    <b>Nom du site</b>
                </div>
                <div>
                    Ville
                </div>
                <div>
                    Code postal
                </div>
                <div>
                    Cumul HT
                </div>
            </div>
            {
                state?.quotationList?.map((quotation, index) =>
                    <div key={index} className={"site-quotation-row parent"}>
                        <div style={{
                            display: "inline-grid",
                            gridTemplateColumns: "repeat(4, 1fr)",
                            width: "100%",
                            justifyItems: "center"
                        }} onClick={() => setSelectedRow(selectedRow === quotation.id ? null : quotation.id)}>
                            <div>
                                <b>{quotation.name}</b>
                            </div>
                            <div>
                                {quotation.ville}
                            </div>
                            <div>
                                {quotation.cp}
                            </div>
                            <div>
                                {getBeautifulAmount(quotation.devis.flatMap(d => d.interventions).flatMap(i => i.quotations).reduce((acc, q) => acc+=q.amountUntaxed, 0))} €
                            </div>
                        </div>
                        <div style={{maxHeight: selectedRow === quotation.id ? "800px" : "0px"}} className={"panel"}>
                            {
                                quotation.devis.map((devis, index) =>
                                    <div key={index} className={"site-quotation-row"}>
                                        <div>
                                            <a target={"_blank"}
                                               href={`/devis/${devis.id}`}>{devis.clientIdBCommande}</a>
                                        </div>

                                        <div>
                                            {
                                                devis.interventions.map((intervention, index) =>
                                                    <div key={index}
                                                         className={"site-quotation-row"}>
                                                        <a target={"_blank"}
                                                           href={`/Intervention/${intervention.id}`}>Intervention
                                                            d'origine</a>


                                                        <div style={{
                                                            display: "inline-grid",
                                                            gridTemplateColumns: "repeat(3, 1fr)",
                                                            width: "100%",
                                                            justifyItems: "center"
                                                        }}>
                                                            <div>
                                                                N° devis
                                                            </div>
                                                            <div>
                                                                État du devis
                                                            </div>
                                                            <div>
                                                                Montant HT
                                                            </div>
                                                        </div>

                                                        {
                                                            intervention.quotations.map((quotation, index) =>
                                                                <div style={{
                                                                    display: "inline-grid",
                                                                    gridTemplateColumns: "repeat(3, 1fr)",
                                                                    width: "100%",
                                                                    justifyItems: "center"
                                                                }}>
                                                                    <div>
                                                                        <a target={"_blank"}
                                                                           href={`/quotation/${quotation.id}`}>{quotation.odooRef}</a>
                                                                    </div>
                                                                    <div>
                                                                        {text.STATUS_TRADUCTION[quotation.status]}
                                                                    </div>
                                                                    <div>
                                                                        {quotation.amountUntaxed} €
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}