import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import constants, {text} from "../components/util/constants";
import {EditText, EditTextarea} from "react-edit-text";
import {IconButton, Step, StepLabel, Stepper, Tooltip} from "@mui/material";
import CommentIcon from "../images/comment.png";
import QuotationForm from "./QuotationForm";
import Modal from "react-responsive-modal";
import PenIcon from "../images/pen-edit-icon.png";
import ClientForm from "../components/forms/ClientForm";
import SearchClient from "../components/forms/SearchClient";
import {DocumentLoaderList} from "../components/util/DocumentLoader";
import EditSupply from "../components/forms/EditSupply";
import IconMap from "../images/button-map.png";
import Layer from "../layer/Layer";
import {v4} from "uuid";
import {useMediaQuery} from "react-responsive";
import "../components/style/Quotation.css"

export default function Quotation() {

    let {id} = useParams()
    const {enqueueSnackbar}=useSnackbar()
    const [devis, setDevis] = useState({})
    const [quotation, setQuotation] = useState(false)
    const [provisionalWork, setProvisionalWork] = useState([])
    const [jobRepair, setJobRepair] = useState([])
    const [points, setPoints] = useState(false)
    const api = new IbatApi();

    useEffect(() => {
        if(!quotation){
            getQuotation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    function getQuotation() {

        api.getQuotation(id)
            .then(res => {
                setDevis({...devis, ...res})
                let q = res.interventions.flatMap(i => i.quotations).find(quotation => quotation.id === +id)
                q = {...q, billEntity: q.billEntity ?? res.client}
                setQuotation(q)
            })
            .catch(() => {
                enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
            })

        api.getPoiTypes("provisionalWork")
            .then(r => setProvisionalWork(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

        api.getPoiTypes("jobRepair")
            .then(r => setJobRepair(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }


    const processAmount = () => {
        let amount = quotation?.quotationGroup
            ?.flatMap(qg => qg.lineGroup)
            ?.flatMap(lg => lg.product)
            ?.map(p => Number.parseFloat(p.m_pvht ?? (p.pvht ?? 0))*Number.parseFloat(p.m_qty??(p.qty ?? 0)))
            ?.reduce((a, v) => a = a+v,0)
        return Number.parseFloat(amount)?.toFixed(2)
    }
    const getBeautifulAmount = (value) => {
        let val = value ?? processAmount()
        val =  new Intl.NumberFormat('fr-FR')?.format(val)
        return val
    }


    return (
        <div className={"page scrollable"}>
            <aside className={"criteria-bar"}>
                <div className={"margin-content"}>
                    <Tooltip title={text.TOOLTIP_DISPLAY_QUOTATION_MAP}>
                        <IconButton onClick={()=> setPoints(
                            {list: quotation?.quotationGroup?.flatMap(qg => qg.lineGroup)?.flatMap(p => p.point).filter(p => p !== null), addMode: false}
                        )
                        }><img alt={"img"} src={IconMap }/></IconButton>
                    </Tooltip>
                </div>
            </aside>
            <div className={"flex-column devis-bean"}>

                <div className={"references"}>
                    <Tooltip title={"Référence du devis"}>
                        <div>
                            <h1>{quotation.odooRef}</h1>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Référence interne du devis"}>
                        <div className={"bord1 ref-edit"}>{quotation.name ?? constants.NULL}</div>
                    </Tooltip>
                </div>
                <div className={"documents"}>
                    {quotation.odooId &&
                        <DocumentLoaderList name={[quotation.odooId]} noHeader={true} type={"quotation"}/>}
                </div>
                <div className={"infos seondary-section w100"}>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.AMOUNT_UNTAXED}
                        </div>
                        <div className={"tabcell"}>
                            {quotation && (getBeautifulAmount(quotation.amountUntaxed))}€
                        </div>
                        <div className={"tabcell"}>
                            {text.DEPOSIT}
                        </div>
                        <div className={"tabcell"}>
                            {quotation.amount ?? constants.NULL}€
                        </div>
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.FACTURATION_ENTITY}
                        </div>
                        {
                            quotation?.billEntity &&
                            <div>
                                {quotation.billEntity.name}
                            </div>
                        }

                        <div className={"tabcell"}>
                            {text.DELIVERY_ENTITY}
                        </div>
                        {
                            (devis.site || quotation.deliveryEntity) &&
                            <a rel={"noreferrer"} className={`tabcell`} target={"_blank"}
                               href={`/site/${devis?.site?.id ?? quotation?.deliveryEntity?.id}`}> {devis?.site?.name ?? quotation?.deliveryEntity?.name}</a>
                        }
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>{text.CREATION_DATE}</div>
                        <div
                            className={"tabcell"}>{quotation.createdAt && new Date(quotation.createdAt).toLocaleDateString()}</div>
                        <div className={"tabcell"}>{text.EXPIRATION_DATE}</div>
                        <div
                            className={"tabcell"}>{quotation.validityDate && new Date(quotation.validityDate).toLocaleDateString()}</div>
                    </div>
                </div>
                <div className={"details w100"}>
                    {
                        quotation &&
                        <QuotationForm
                            supply={[]}
                            mQuotation={quotation}
                            setPoints={setPoints}
                        />}
                </div>

                <Modal open={points} onClose={() => setPoints(false)}>
                    <Layer points={points.list}
                           background={devis?.site?.imageUrl}
                           provisionalWork={provisionalWork}
                           jobRepair={jobRepair}
                           addMode={points.addMode}
                           onSave={points.onSave}
                           lineGroupId={points.lineGroupId}
                    />
                </Modal>

            </div>
        </div>
    )
}