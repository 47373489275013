import Table from "../../table/Table";
import {text} from "../util/constants";
import React from "react";

export default function QuotationsViewByQuotation({loading, handleChangeRowsPerPage, handleChangePage, getQuotations, handleColumns, handleFilter, state, setTyping, clearFilter}) {
    return    <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
           loading={loading}
           onHandleChangePage={handleChangePage}
           onGetData={getQuotations}
           onHandleColumns={handleColumns}
           onHandleFilter={handleFilter}
           rowCount={state.count}
           onEndTyping={setTyping}
           filter={state.filters}
           pageNumber={state.pageNumber}
           pageSize={state.pageSize}
           columns={state.columns}
           list={state.quotationList}
           onClearFilters={clearFilter}
           mapdisplay={true}
           tabName={text.QUOTATIONS}
    />
}

