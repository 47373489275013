import "./loading.css";
import React, {Fragment} from "react";

export default function Loading({length}){
    return(
        <Fragment>
            <tbody className="loadingTable">

            {new Array(20).fill(1).map((tr, idx)=>
                                        <tr key={idx}>
                                            {new Array(length).fill(1).map((t, idx2)=>
                                                <td key={idx2}><center>
                                                    {Math.random()>.5?<span className={Math.random()>.5?"sub-temp":"sub-temp-three"}/>:""}
                                                    {Math.random()>.3?<span className={Math.random()>.5?"sub-temp":"sub-temp-three"}/>:""}</center>
                                                </td>)}
                                        </tr>)}


            </tbody>

        </Fragment>
    )
}