import {useEffect, useState} from "react";
import {text} from "../util/constants";

export default function QuotationsViewByElements ({state}) {

    const [selectedRow, setSelectedRow] = useState(null)
    const getBeautifulAmount = (value) => {
        let val = value
        val =  new Intl.NumberFormat('fr-FR')?.format(val)
        return val
    }

    useEffect(() => {}, [state]);

    return (

        <div style={{display: "grid", width: "94vw", rowGap: ".5rem"}}>

            <div style={{
                width: "100%",
                padding: ".5rem"
            }}>
            </div>
            {
                state?.quotationList?.map((quotation, index) =>
                    <div key={index} className={"site-quotation-row parent"} onClick={() => setSelectedRow(selectedRow == quotation.typeId ? null : quotation.typeId)}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h2>{quotation.typeName}</h2>
                            <h2>
                                {
                                    getBeautifulAmount(
                                        quotation.sites.flatMap(s => s.devis)
                                            .flatMap(d => d.interventions)
                                            .flatMap(i => i.quotations)
                                            .flatMap(q => q.products)
                                            .reduce((acc, p) => acc+=(p.pvht*p.qty), 0)
                                    )
                                }€
                            </h2>
                        </div>
                        {
                            selectedRow == quotation.typeId &&
                            <div style={{display: "flex", flexDirection: "column", gap: '1rem'}}>
                                {
                                    quotation.sites.map((site, index) =>

                                        <div key={index}>
                                            <div>
                                                <b>{site.name}</b><br /> {site.cp}, {site.ville}
                                            </div>
                                                {
                                                    site.devis.map((devis, index) =>
                                                        <div key={index}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;<a target={"_blank"} href={`/devis/${devis.id}`}>{devis.clientIdBCommande}</a>
                                                            {
                                                                devis.interventions.map((intervention, index) =>
                                                                    <div key={index}>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a target={"_blank"} href={`/Intervention/${intervention.id}`}>Intervention #{intervention.id}</a>
                                                                        {
                                                                            intervention.quotations.map((quotation, index) =>
                                                                                <div key={index} style={{padding: "0 4rem"}}>
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a target={"_blank"} href={`/quotation/${quotation.id}`}>{quotation.odooRef}</a>

                                                                                    <div key={index} style={{
                                                                                        display: "inline-grid",
                                                                                        gridTemplateColumns: "repeat(4, 1fr)",
                                                                                        width: "100%",
                                                                                        justifyItems: "center",
                                                                                        alignItems: "center",
                                                                                        rowGap: ".5rem"
                                                                                    }}>
                                                                                        {
                                                                                        quotation.products.map((product, index) =>
                                                                                            <>
                                                                                                <div><b>{product.productName}</b></div>
                                                                                                <div>{getBeautifulAmount(product.pvht)}€</div>
                                                                                                <div>{product.qty}{product.unit}</div>
                                                                                                <div>{getBeautifulAmount(product.pvht * product.qty)}€</div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    )
                                                }

                                        </div>
                                    )
                                }
                            </div>
                        }
                    </div>
                )
            }
        </div>
    )
}