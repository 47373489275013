import React, {Fragment, useEffect, useState,} from "react";
import {IconButton, TextareaAutosize, Tooltip} from "@mui/material";
import constants, {text} from "../components/util/constants";
import IconSee from "../images/eye-see.png";
import IconNotSee from "../images/eye-no-see.png";
import IbatApi from "../utils/IbatApi";
import {useSnackbar} from "notistack";
import Modal from "react-responsive-modal";
import SearchSite from "../components/forms/SearchSite";
import SearchClient from "../components/forms/SearchClient";
import CustomImage from "../components/util/CustomImage";
import Select from "react-select";
import {getUID} from "../utils/Utils";
import IconMap from "../images/button-map.png";
import {v4} from "uuid";
import {useMediaQuery} from "react-responsive";


export default function QuotationForm({
                                          mQuotation,
                                          setPoints
                                      }) {
    const [quotation, setQuotation] = useState(mQuotation)
    const [carousel, setCarousel] = useState(false)
    const [previewPDF, setPreviewPDF] = useState(false)


    const gridColumn = [
        {name: "Article", classname: "product-name"},
        {name: "Prix de vente HT"},
        {name: "Quantité"},
        {name: "Unité"},
        {name: "Taux T.V.A"},
        {name: "Total H.T"}
    ]

    const tvaList = [
        {value: 0, label: "0%"},
        {value: 10, label: "10%"},
        {value: 20, label: "20%"}
    ]
    const unitList = [
        {value: "m", label: "Mètre"},
        {value: "m²", label: "Mètre carré"},
        {value: "m3", label: "Mètre cube"},
        {value: "mL", label: "Mètre linéaire"},
        {value: "kg", label: "Kilogramme"},
        {value: "L", label: "Litre"},
        {value: "F", label: "Forfait"},
        {value: "H", label: "Heures"},
        {value: "J", label: "Jours"},
        {value: "U", label: "Unité"},
    ]


    const {enqueueSnackbar} = useSnackbar()
    const api = new IbatApi()
    useEffect(() => {
        setQuotation(mQuotation)
        if (previewPDF === 1) {
            setPreviewPDF(-1)
            api.previewQuotationPdf(mQuotation.odooId)
                .then(r => setPreviewPDF(r))
                .catch(() => enqueueSnackbar("Une erreur est survenue lors de la prévisualisation du devis", {variant: "error"}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mQuotation])



    function showLineGroup(lineGroup, qg_idx, idx) {
        return (
            <div className={"poi-chapter tertiary-section"} key={v4()}>


                <div className={"flex-row"}>
                    <IconButton onClick={() => setPoints({
                        list: lineGroup?.point ? [lineGroup?.point] : [],
                        addMode: false
                    })}>
                        <img alt={"img"} src={IconMap}/>
                    </IconButton>
                    <div className={"grid except-addBtn bord1"}>

                        {
                            gridColumn.map((c, idx) =>
                                <div key={idx}
                                     className={`bord1 fs1 table-header flex-row ${c.classname ?? ""}`}>
                                    {c.name}
                                </div>
                            )
                        }

                        {
                            lineGroup?.product?.map((sp, idxRow) =>
                                <Fragment key={idxRow}>

                                    <div className={"bord1 fs1 product-name flex-column"}>
                                        <div>{sp?.actionType ? `(${text[sp?.actionType]}) ` : ""} {sp?.productName} {sp.variantValue ?? ""}</div>
                                    </div>
                                    <div>{sp.m_pvht ?? (sp.pvht ?? 0)}</div>
                                    <div>{sp.m_qty ?? (sp.qty ?? "")}</div>
                                    <div>{unitList.filter(f => f.value === (sp?.m_unit ?? (sp?.unit ?? "U")))[0].label}</div>
                                    <div>{tvaList.filter(f => f.value === (sp.m_ttva ?? (sp.ttva ?? 20)))[0].label}</div>
                                    <div>{Number(Number(sp.m_pvht ?? (sp.pvht ?? 0)) * Number(sp.m_qty ?? (sp.qty ?? 0))).toFixed(2)}</div>
                                </Fragment>
                            )
                        }
                    </div>
                </div>

                {
                    lineGroup?.clientInformation &&
                    <div className={"flex-column comments primary-section"}>
                        <div className={"flex-row"}>
                            <Tooltip title={"Informations client"}>
                                <b className="clientinfo-title">ℹ</b>
                            </Tooltip>
                            <p>{lineGroup?.clientInformation ?? ""}</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    function showQuotationGroup(quotationSection, qg_idx) {
        return (
            <div>
                <div className={"flex-column"}>
                    <div key={qg_idx} className={"flex-column chapter secondary-section w100"}>
                        <div className={"chapter-title flex-row auto-line secondary-section"}>
                            <IconButton onClick={() => setPoints({
                                list: [...quotationSection?.lineGroup?.flatMap(lg => lg.point)] ?? [],
                                addMode: false
                            })}>
                                <img alt={"img"} src={IconMap}/>
                            </IconButton>
                            {quotationSection.typeName}
                        </div>
                        <ul>
                            {
                                quotationSection?.lineGroup?.map((p, idx) => showLineGroup(p, qg_idx, idx))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={"quotation w100 flex-column primary-section"}>

            <div className={"client-title secondary-section flex-column w100"}>
                <h1>{quotation?.clientTitle ?? ""}</h1>
            </div>
            {

                quotation &&
                quotation?.quotationGroup?.map((qg, idx) => {
                    return {...qg, position: qg.position ?? idx}
                }).sort((q1, q2) => q1.position > q2.position ? 1 : -1).map((p, idx) =>
                    showQuotationGroup(p, idx)
                )
            }

            <Modal classNames={{root: 'transparent'}} open={carousel}
                   onClose={() => setCarousel(false)}>
                <div className={"caroussel-container primary-section"}>
                    <div className={"zoomHowto"}>{text.ZOOMHOWTO}</div>
                    <div className={"caroussel flex-row rounded"}>
                        <div className={"indexNumber rounded"}>{carousel.current + 1}</div>


                        {
                            carousel.current > 0 &&
                            <IconButton onClick={() => setCarousel({
                                ...carousel,
                                current: carousel?.current - 1
                            })}>‹</IconButton>
                        }

                        <div className={"keep-space"}>
                            <CustomImage className={"rounded"}
                                         name={carousel?.pictures?.[carousel?.current]}/>
                        </div>
                        {
                            carousel?.current < carousel?.pictures?.length - 1 &&
                            <IconButton onClick={() => setCarousel({
                                ...carousel,
                                current: carousel?.current + 1
                            })}>›</IconButton>
                        }

                    </div>

                </div>
            </Modal>

            <Modal open={previewPDF} onClose={() => setPreviewPDF(null)}>
                {
                    previewPDF === 1 || previewPDF === -1 ?
                        <div/> :
                        <embed className={"pdf-modal"}
                               src={`data:application/pdf;base64,${previewPDF}`}/>
                }
            </Modal>

        </div>
    )
}