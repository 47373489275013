import * as axios from "axios";
import constants, {text} from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";


export default class IbatApi {

  /*
  * User & Fonctionnal part
  */
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_ENDPOINT;
    // this.api_url = "http://192.168.31.50:8002";
    // this.api_url = "http://localhost:8002";
    // this.api_url = "https://backend.ebat.tegeo.fr";
    this.domain =process.env.REACT_APP_DOMAIN
    // this.domain ="192.168.31.50"
  }
  init = () => {
    this.api_token = this.getCookie("Token");

    let headers = {
      Accept: "*",
    };

    if (this.api_token) {
      headers.Authorization = `${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 3600000,
      headers: headers,
    });

    return this.client;
  };
  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getUrl() {
    return this.api_url;
  }
  getToken() {
    return this.getCookie("Token")
  }
  getTokenData() {
    let token = this.getToken();
    if (token && token !== 'null') {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''));
      return JSON.parse(jsonPayload)
    }

  }
  login(loginData) {
    return this.init().post("/client/login", loginData).then(resp => {
      document.cookie = `Token=${resp.headers.token};expires=${new Date(new Date().getTime() + (24 * 60 * 60 * 1000))};path=/;domain=${this.domain}`;
      return document.cookie
    }).catch((error) => {
      error.response && alert(text.INVALID_LOGIN)
    })
  }
  deconexion() {
    document.cookie = `Token=${this.api_token};expires=${new Date(new Date().getTime() - (60 * 60 * 1000))};path=/;domain=${this.domain}`;
    window.location = "/login"
  }
  hasRole(role) {
    const authorities = this.getTokenData()?.authorities;
    const roles = authorities
    return roles?.includes(role) || roles?.includes("OWNER");
  }


  getPrefSite(){
    return localStorage.getItem("prefSite")
  }
  setPrefSite(toAdd){
    let site = this.getPrefSite()
    if (!site || site === "noOne") {
      site = toAdd
    }
    else {
      let siteList = site.split(",")
      if (!siteList.includes(""+toAdd)){
        siteList.push(toAdd)
      }
      site = siteList.join(",")
    }
    localStorage.setItem("prefSite", site)
  }
  removePrefSite(toRemove){
    let site = this.getPrefSite().split(",")
    site = site.filter(s=>s!==""+toRemove)
    if (site.length === 0){
      site = "noOne"
    }
    localStorage.setItem("prefSite", site)
  }


  /*
  * Client=>Company part
  */
  getCompanyById = (id) => {
    return this.init().get("/client/company/" + id).then(resp => resp.data)
  }
  getStats(id, params){
    return this.init().get("/client/company/" + id + "/stats", {params: params ? params : {}}).then(resp => resp.data)
  }
  getCms(id, params){
    return this.init().get("/client/company/" + id + "/cms", {params: params ? params : {}}).then(resp => resp.data)
  }
  getDelay(id, params){
    return this.init().get("/client/company/" + id + "/delay", {params: params ? params : {}}).then(resp => resp.data)
  }
  getCompanies(params) {
    return this.init().get("/client/companies/filter", {params: params ? params : {}}).then(resp => resp.data)
  }
  getAllCompaniesByFilter(params) {
    return this.init().get("/client/allCompanies/filter", {params: params ? params : {}}).then(resp => resp.data)
  }
  getAllClientByFilter(params, creation) {
    return this.init().get("/client/allClient/filter", {params: params ? params : {}}).then(resp => resp.data)
  }
  findClient(clientName) {
    return this.init().get("/core/client/find", {params: {name: clientName}}).then(resp => resp.data)
  }
  addClientInteraction = (id, interactionClient, devis) => {
    return this.init().put(`/core/${devis ? "devis" : "intervention"}/${id}/interractionClient`, interactionClient)
      .then(resp => resp.data)
  }
  getClientInteraction = (id, hide) => {
    return this.init().get(`/client/${hide ? "devis" : "intervention"}/${id}/interractionClient`)
      .then(resp => resp.data)
  }
  getClientSite(id) {
    return this.init().get("/core/client/" + id + "/site").then(resp => resp.data)
  }
  addServiceProvider(id){
    return this.init().post(`/client/addServiceProvider/${id}`).then(resp=>resp.data)
  }


  /*
  * Site part
  */
  getSite = (id) => {
    return this.init().get("/client/site/" + id).then(resp => resp.data);
  };
  getSiteByIds(ids) {
    return this.init().get("/client/site/listByIds", {params: {ids: ids}}).then(resp => resp.data);
  }
  getSiteFilterd = (params) => {
    return this.init().get("/client/site/filter", {params: params ? params : {}}).then(resp => resp.data);
  };
  getAllSiteFilterd = (params) => {
    return this.init().get("/client/site/all/filter", {params: params ? params : {}}).then(resp => resp.data);
  };
  findSite(siteName) {
    return this.init().get("/client/site/find", {params: {site: siteName}}).then(resp => resp.data);
  }
  getSiteIntervention(id) {
    return this.init().get("/client/site/" + id + "/intervention").then(resp => resp.data)
  }
  getSiteClient(id){
    return this.init().get("/client/siteClient/" + id).then(resp => resp.data)
  }
  createSite(siteApi) {
    return this.init().post("/client/site", siteApi).then(resp => resp.data)
  }
  updateClientSite(id, data){
    return this.init().post("/client/siteClient/" + id, data).then(resp => resp.data)
  }
  siteArchive(id){
    return this.init().post(`/client/siteClient/archive/${id}`).then(resp => resp.data)
  }
  shareSite(siteId, siren, siret){
    return this.init().post(`client/shareSite/${siteId}?targetSiren=${siren}&targetSiret=${siret}`).then(resp => resp.data)
  }

  /*
  * Intervention part
  */
  normalize = ({dateIntervention, devis, estimateDuration, endIntervention, id, status, type, estimate, bill, event, jobType, availability}) => {
    let intervention=(dateIntervention>0)?dateIntervention:event?event.start:0
    return {
      intervention: {
        id: id,
        name: intervention <= 0 || intervention===null ? (availability > 0 ? new Date(availability).toLocaleDateString() : "-") : (new Date(intervention).toLocaleDateString()+" "+new Date(intervention).toLocaleTimeString())
      },
      reception: new Date(devis.dateReception).toLocaleDateString(),
      devis: {
        id: devis.id,
        name: devis.clientIdBCommande ? devis.clientIdBCommande : constants.NULL,
        warning: !devis.clientIdBCommande,
        insurance: devis.insurance ?? constants.NULL
      },
      end: endIntervention === 0 ? "-" : new Date(endIntervention).toLocaleDateString() + " " + new Date(endIntervention).toLocaleTimeString(),
      rapport: text.DOWNLOAD_REPORT,
      id: id,
      bill: {id: id, name: bill},
      estimate: {id: id, name: estimate},
      company: {id: devis.company.id, name: devis.company.name},
      estimateDuration: {id: id, name: `${timeToDate(estimateDuration * 1000)}`, value: estimateDuration},
      site: {id: devis.site.id, name: devis.site.name, warning: !devis.site.latitude, phone: devis.site.contactPhone},
      interactions: {warning: devis.interactionClients == null},
      cp: devis.site.cp,
      ville: devis.site.ville,
      address: devis.site.address,
      status: text.STATUS_TRADUCTION[status],
      // supply: {id: devis.supply.id, name: devis.supply.ready === constants.READY ? text.READY : text.INCOMPLETE},
      longitude: devis.site.longitude,
      latitude: devis.site.latitude,
      type: {id: type.id, name: type.name},
      jobType: jobType
    }
  }

  normalizeQuotation = ({devisId, devisName, interventionId, name, odooId, id, siteId, siteName, facturationEntityId, facturationEntityName, facturationEntityType, status, dateSent, odooRef, billEntityName, billEntityId}) => {
    let normalized = {
      id: id,
      name: {id: id, name: name ?? "-", target: "quotation"},
      odooRef: {id: id, name: odooRef ?? "-", target: "quotation"},
      devis: {id: devisId, name:devisName ?? devisId},
      Interventions: {id: interventionId, name : interventionId},
      site: {id: siteId, name: siteName ?? siteId},
      facturationEntity: billEntityId ? {id: billEntityId, name: billEntityName, target: "client"} : {id: facturationEntityId, name: facturationEntityName, target: "client"},
      status: text.STATUS_TRADUCTION[status],
      dateSent: {id: id, name: dateSent ? new Date(dateSent).toLocaleDateString(): "-", target : "quotation"}
    }
    return normalized
  }

  getFilterIntervention = (params) => {
    return this.init().get("/client/intervention/filter", {params: params ? params : {}})
      .then(respose => {
        return {
          interventions: respose.data.content.map(e => this.normalize(e)),
          count: respose.data.totalElements
        }
      })

  };
  getIntervention = (id) => {
    return this.init().get("/client/intervention/" + id).then(response => response.data)
  }
  getStatus() {
    return this.init().get("/client/interventionStatus").then(d => d.data)
  }
  getAllOperationType(jobType) {
    return this.init().get(`/metier/operationType/?jobType=${jobType}`).then(resp => resp.data);
  }

  genInterventionPdf(state, persist) {
    let headers={Authorization:this.api_token};
    let client = axios.create({
      baseURL: this.api_url,
      timeout: 900000,
      headers: headers,
    })
    return client.post(`/pdf/interventionReport`,state,{responseType: 'blob'}).then(d=>d.data)
  }
  genSecurityPdf(state) {
    let headers={Authorization:this.api_token};
    let client = axios.create({
      baseURL: this.api_url,
      timeout: 900000,
      headers: headers,
    })
    return client.post(`/pdf/securityReport`,state,{responseType: 'blob'}).then(d=>d.data)
  }
  addReport(id, blobUrl) {
    return axios.create({timeout: 310000}).get(blobUrl, {responseType: 'blob'}).then(pdfFile => {
          const reader = new FileReader();
          reader.readAsDataURL(pdfFile.data);
          reader.onload = () => {
            const b64 = reader.result.replace(/^data:.+;base64,/, '');
            const data = [{type: 'pdf', data: b64}]
            return this.init().put(`/core/intervention/${id}/report`, data).then(d => d.data)
          }
        }
    )
  }


  /*
  * Intervention/Operation part
  */
  getInterventionOperation(id) {
    return this.init().get(`/core/intervention/${id}/operation`).then(d => d.data)
  }
  getOperation(id) {
    return this.init().get(`/core/operation/${id}`).then(d => d.data)
  }
  cancelIntervention(id) {
    return this.init().delete(`/client/event/intervention/${id}`)
  }
  addIntervention(idDevis, intervention) {
    let isMandatory = intervention.idTypeIntervention===constants.INTERVENTION_ENTRETIEN;
    return this.init().put(`/client/devis/${idDevis}/intervention?isMandatory=${isMandatory}`,intervention).then(resp=>resp.data)
  }


  /*
  * Devis part
  */
  findDevis(idbcomande) {
    return this.init().get(`/core/devis/find?idBcommande=${idbcomande}`).then(resp => resp.data)
  }
  getDevis(id) {
    return this.init().get(`/client/devis/${id}`).then(resp => resp.data)
  }
  getDevisIntervention(id) {
    return this.init().get(`/client/devis/${id}/intervention`).then(resp => {
      return resp.data
    })

  }
  getRequestIntervention(idList) {
    return this.init().get(`/client/devis/intervention?idList=${idList}`, {idList: idList}).then(resp => resp.data)

  }
  getDevisSupply(id) {
    return this.init().get(`/core/devis/${id}/supply`).then(resp => resp.data)
  }
  shareDevis(devisId, siren, siret){
    return this.init().post(`client/shareDevis/${devisId}?targetSiren=${siren}&targetSiret=${siret}`).then(resp => resp.data)
  }
  updateDevisCommentClient(id, clientConcern) {
    return this.init().post(`/client/devis/${id}?clientComment=${clientConcern}`).then(resp => resp.data)
  }
  getInterventionQuotation(id) {
    return this.init().get(`/client/intervention/${id}/quotations`).then(resp => resp.data);
  }
  getOdooQuotationPdf(type, odooId) {
    return this.init().get(`/client/${type}/${odooId}/pdf`).then(resp => {
      if (!resp.data) {
        return this.previewQuotationPdf(odooId)
      }
      else {
        return resp.data
      }
    } );
  }

  previewQuotationPdf(odooId) {
    return this.init().get(`/client/quotation/${odooId}/pdfPreview`).then(resp => resp.data);
  }

  /*
  * Request part
  */
  createRequest(id, interventionApi) {
    return this.init().post(`/client/siteClient/${id}/request`, interventionApi).then(resp => resp.data)
  }
  createCommande(siteId, requestId, interventionApi) {
    let isMandatory = interventionApi.intervention.idTypeIntervention === constants.INTERVENTION_ENTRETIEN
    return this.init().post(`/client/request/${requestId}/new?siteId=${siteId}&isMandatory=${isMandatory}&companyId=${interventionApi.companyId}`, interventionApi).then(resp => resp.data)
  }
  getRequestFiltered = (params) => {
    return this.init().get("/client/request/filter", {params: params ? params : {}}).then(resp => resp.data);
  }
  getRequest(id){
    return this.init().get(`/client/request/${id}`).then(r=>r.data)
  }
  updateRequestComment(id, comment){
    return this.init().post(`/client/request/${id}/update?comment=${comment}`).then(r=>r.data)
  }


  /*
  * Supply part
  */
  getSupply(id) {
    return this.init().get("/core/intervention/" + id + "/supply").then(r => r.data)
  }
  getConstructionTypesMachinery() {
    return this.init().get("/core/constructionMachineryType").then(resp => resp.data)
  }
  getContructionTypesDocument() {
    return this.init().get("/core/constructionDocumentType").then(resp => resp.data)
  }
  getAdministrationTypesDocument() {
    return this.init().get("/core/administrationDocumentType").then(resp => resp.data)

  }
  getConstructionTypesMaterials() {
    return this.init().get("/core/constructionMaterialType").then(resp => resp.data)
  }


  /*
  * File part
  */
  async genBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }
  getFile(fileName) {
    if (fileName.split(".").pop() === "pdf") {
      return this.init().get(`/client/${fileName}`, {responseType: 'blob'})
          .then(async d => ({url:  URL.createObjectURL(new Blob([d.data], {type: 'application/pdf'})), b64: await this.genBase64(d.data)}))
    } else {
      return this.init().get(`/client/${fileName}`, {responseType: 'blob'})
          .then(async d => ({url: URL.createObjectURL(d.data), b64: await this.genBase64(d.data)}))
    }
  }
  getPDF(fileName) {
    return this.init().get(`/client/${fileName}`, {responseType: 'blob'})
      .then(async d => ({url: URL.createObjectURL(d.data), b64: await this.genBase64(d.data)}))
  }
  getAudio(fileName) {
    return this.init().get(`/core/${fileName}`, {responseType: 'arraybuffer'})
      .then(resp => new Uint8Array(resp.data))
      .then(uintArray => uintArray.reduce((data, byte) => data + String.fromCharCode(byte), '',))
      .then(uintString => btoa(uintString))
      .then(base64 => `data:audio/mp3;base64,${base64}`)
  }
  generateCsv(filters, columns) {
    return this.init().put("/client/intervention/filter/csv", {}, {params: filters})
      .then(d => {
        let data = new Blob([d.data], {type: "octet/stream"});
        var url = window.URL.createObjectURL(data);
        let ahref = document.createElement("a");
        ahref.setAttribute("href", url);
        ahref.setAttribute("download", "result.csv")
        ahref.click()
      })
  }
  genPdf(state, persist) {
    let headers = {Authorization: this.api_token};
    let client = axios.create({
      baseURL: this.api_url,
      timeout: 900000,
      headers: headers,
    })
    return client.post(`/pdf/generate`, state, {responseType: 'blob'}).then(d => d.data)
  }


  getTypes() {
    return this.init().get("/client/interventionType").then(resp => resp.data)
  }
  getMyUserId() {
    let data = this.getTokenData();
    return data.userId
  }
  creatIntervention(id, interventionApi) {
    let isMandatory = interventionApi.intervention.idTypeIntervention === constants.INTERVENTION_ENTRETIEN
    return this.init().post(`/client/site/${id}/devis?isMandatory=${isMandatory}&companyId=${interventionApi.companyId}`, interventionApi).then(resp => resp.data)
  }
  getJobTypes(){
    return this.init().get(`/client/getJobTypes`).then(resp=>resp.data)
  }

  getAllUser() {
    return this.init().get("/client/getUsers").then(resp => resp.data)
  }
  creatUser(utilisateur) {
    return this.init().post("/client/user", utilisateur).then(resp => resp.data)
  }
  updateUser(utilisateur) {
    return this.init().put("/client/user/update", utilisateur).then(resp => resp.data)
  }
  deleteUser(uid) {
    return this.init().delete(`/client/user/${uid}`).then(resp => resp.data)
  }

  /*
  addTeamInteraction(id, data) {
    return this.init().put(`/core/intervention/${id}/interractionTeams?message=${data}`)
  }
  getSecteur() {
    return this.init().get("/core/secteur").then(resp => resp.data)
  }
  getGestionnaire() {
    return this.init().get("/core/myUser/gestionnaire").then(resp => resp.data)
  }
  createClient(clientApi) {
    return this.init().post("/core/client", clientApi).then(resp => resp.data)
  }
  setInterventionTeam(idIntervention, temaId) {
    return this.init().put("/core/intervention/" + idIntervention + "/team?teamId=" + temaId).then(resp => resp.data)
  }
  removeInterventionTeam(idIntervention) {
    return this.init().delete("/core/intervention/" + idIntervention + "/team").then(resp => resp.data)
  }
  setInterventionDate(idIntervention, dateIntervention) {
    return this.init().put("/core/intervention/" + idIntervention + "/dateIntervention?date=" + dateIntervention).then(resp => resp.data)
  }
  removeInterventionDate(idIntervention) {
    return this.init().delete("/core/intervention/" + idIntervention + "/dateIntervention").then(resp => resp.data)
  }
  addDocumentSupply(id, document) {
    return this.init().put("/core/supply/" + id + "/document", document).then(resp => resp.data)
  }
  addAdminDocumentSupply(id, document) {
    return this.init().put("/core/supply/" + id + "/adminDocument", document).then(resp => resp.data)
  }
  addMachinerySupply(id, machinery) {
    return this.init().put("/core/supply/" + id + "/machinery", machinery).then(resp => resp.data)

  }
  addMaterialSupply(id, material) {
    return this.init().put(`/core/supply/${id}/material`, material).then(resp => resp.data)
  }
  sendAdminDocument(id, state) {
    return this.init().put("/core/administrationDocument/" + id, state).then(resp => resp.data)

  }
  sendAdminDocumentDocs(id, docs) {
    return this.init().put(`/core/administrationDocument/${id}/documents`, docs).then(resp => resp.data)
  }
  sendDocument(id, state) {
    return this.init().put("/core/constructionDocument/" + id, state).then(resp => resp.data)
  }
  sendDocumentDocs(id, docs) {
    return this.init().put(`/core/constructionDocument/${id}/documents`, docs).then(resp => resp.data)
  }
  sendMachinery(id, state) {
    return this.init().put("/core/constructionMachinery/" + id, state).then(resp => resp.data)
  }
  sendMachineryDocs(id, docs) {
    return this.init().put(`/core/constructionMachinery/${id}/documents`, docs).then(resp => resp.data)
  }
  sendMaterial(id, state) {
    return this.init().put("/core/constructionMaterial/" + id, state).then(resp => resp.data)
  }
  sendMaterialDocs(id, docs) {
    return this.init().put(`/core/constructionMaterial/${id}/documents`, docs).then(resp => resp.data)
  }
  deleteMachinery(supplyId, idElem) {
    return this.init().delete("/core/supply/" + supplyId + "/machinery?idElem=" + idElem).then(resp => resp.data)
  }
  deleteMaterial(supplyId, idElem) {
    return this.init().delete("/core/supply/" + supplyId + "/material?idElem=" + idElem).then(resp => resp.data)
  }
  deleteDocument(supplyId, idElem) {
    return this.init().delete("/core/supply/" + supplyId + "/document?idElem=" + idElem).then(resp => resp.data)
  }
  deleteAdminDocument(supplyId, idElem) {
    return this.init().delete("/core/supply/" + supplyId + "/admindocument?idElem=" + idElem).then(resp => resp.data)
  }
  createOperationType(operationType, jobType) {
    return this.init().post(`/metier/operationType/${jobType}`, operationType).then(resp => resp.data)
  }
  createOperation(idIntervention, operationType) {
    const opApi = operationType
    console.log(opApi)
    return this.init().put(`/core/intervention/${idIntervention}/mandatory`, opApi)
  }
  updateOperation(id, data) {
    this.init().put(`/core/operation/${id}/gestionnaire?comments=${data.commentaireGestionnaire}`)
    return this.init().put(`/core/operation/${id}/rt?comments=${data.commentaireRT}`)
  }
  getClientGestionnaire(idClient) {
    let myUserId = this.getMyUserId();
    return this.init().get(`/core/client/${idClient}/gestionnaire?idUser=${myUserId}`).then(resp => resp.data)
  }
  setInterventionDuration(id, duration) {
    return this.init().put("/core/intervention/" + id + "/estimateDuration?duration=" + duration).then(resp => resp.data)
  }
  getAllOperationType(jobType) {
    return this.init().get(`/metier/operationType/?jobType=${jobType}`).then(resp => resp.data);
  }
  importDevis(idDevis) {
    return this.init().get(`/core/devis/import?devisRef=${idDevis}`).then(resp => resp.data)
  }
  updateSite(siteId, state) {
    return this.init().put(`/core/site/${siteId}`, state).then(resp => resp.data);
  }
  setInterventionSecteur(id, secteurId) {
    return this.init().put(`/core/intervention/${id}/secteur?secteurId=${secteurId}`).then(resp => resp.data);
  }
  createQuestion(jobType, question) {
    return this.init().post(`/metier/securityQuestion/${jobType}`, question).then(resp => resp.data)
  }
  getQuestion(jobType) {
    return this.init().get(`/metier/securityQuestion/${jobType}`).then(resp => resp.data)
  }
  addAnswer(questionId, answer) {
    return this.init().put(`/metier/securityQuestion/${questionId}/answer`, answer).then(resp => resp.data)
  }
  addConflict(questionId, conflict) {
    return this.init().put(`/metier/securityQuestion/${questionId}/conflicts`, conflict).then(resp => resp.data)
  }
  addPrerequisite(id, questionId, answerId) {
    return this.init().put(`/metier/securityQuestion/${id}/prerequisite?questionId=${questionId}&answerId=${answerId}`).then(d => d.data)
  }
  getAllPicture(interventionId) {
    return this.init().get(`/core/intervention/${interventionId}/pictures`).then(resp => resp.data)
  }
  cancelIntervention(id) {
    return this.init().delete(`/core/event/intervention/${id}`)
  }
  setDevisClientIdBoncommande(id, clientIdBCommande) {
    return this.init().put(`/core/devis/${id}/clientCommande?clientIdBCommande=${clientIdBCommande}`)

  }
  getAllGestionnaire() {
    return this.init().get("/core/myUser/gestionnaire").then(d => d.data)
  }
  setClientGestionnaire(id, idGestionnaire) {
    return this.init().put(`/core/client/${id}/gestionnaire?idGestionnaire=${idGestionnaire}`).then(d => d.data)
  }
  setStateLevel(id, value) {
    return this.init().put(`/core/operation/${id}/stateLevel?stateLevel=${value}`).then(d => d.data)

  }
  addReport(id, blobUrl) {
    return axios.create({timeout: 310000}).get(blobUrl, {responseType: 'blob'}).then(pdfFile => {
        // console.log(pdfFile)
        const reader = new FileReader();
        reader.readAsDataURL(pdfFile.data);
        reader.onload = () => {
          const b64 = reader.result.replace(/^data:.+;base64,/, '');
          const data = [{type: 'pdf', data: b64}]
          return this.init().put(`/core/intervention/${id}/report`, data).then(d => d.data)
        }
      }
    )
  }
  setInterventionStatus(id, status) {
    return this.init().put(`/core/intervention/${id}/status?status=${status}`).then(d => d.data)
  }
  saveResume(id, resume) {
    return this.init().put(`/core/intervention/${id}/resume?commentaire=${resume}`).then(d => d.data)
  }
  saveConclusion(id, resume) {
    return this.init().put(`/core/intervention/${id}/conclusion?commentaire=${resume}`).then(d => d.data)
  }
  setVisible(picId, visible) {
    return this.init().put(`/core/picture/${picId}?visible=${visible}`).then(d => d.data)

  }
  addPicture(opId, picIds) {
    return this.init().put(`/core/operation/${opId}/front/picture`, picIds).then(d => d.data)
  }
  getTeamClock(id, start, end) {
    return this.init().get(`/core/team/${id}/clock?start=${start}&end=${end}`).then(d => d.data)
  }
  getTeamPosition(id, start, end) {
    return this.init().get(`/core/team/${id}/position?start=${start}&end=${end}`).then(d => d.data)
  }
  getTeamPositionDuringIntervention(id, begin, end) {
    return this.init().get(`/core/intervention/rh?teamId=${id}&begin=${begin}&end=${end}`).then(d => d.data)

  }
  saveObject(id, object) {
    return this.init().put(`/core/intervention/${id}/object?commentaire=${object}`).then(d => d.data)

  }
  getProductMaker() {
    return this.init().get(`/metier/productMaker/`).then(d => d.data)
  }
  getProductUtilities() {
    return this.init().get(`/metier/productUtility/`).then(d => d.data)
  }
  createCustomAnswer(customAnswer) {
    return this.init().post(`/metier/customAnswer`, customAnswer).then(d => d.data)
  }
  getProducts() {
    return this.init().get(`/metier/product/`).then(d => d.data)
  }
  createProduct(product) {
    return this.init().post(`/metier/product/`, product).then(d => d.data)
  }
  getHazardStatement() {
    return this.init().get(`/metier/hazardStatement/`).then(d => d.data)
  }
  addHazardStatement(id, selectedHazard) {
    return this.init().put(`/metier/product/${id}`, selectedHazard).then(d => d.data)

  }
  getCustomAnswer() {
    return this.init().get("/metier/customAnswer").then(d => d.data)
  }

  setBill(id, billName) {
    return this.init().put(`/core/devis/${id}/bill?bill=${billName}`).then(d => d.data)

  }
  askQuestion(id, question) {
    return this.init().put(`/core/operation/${id}/question?question=${question}`).then(d => d.data)
  }
  setVisibleOperation(id, visible) {
    return this.init().put(`/core/operation/${id}/visible?visible=${visible}`).then(d => d.data)
  }
  updateClient(d) {
    return this.init().put(`/core/client/${d.id}`, d).then(d => d.data)
  }
  setPaid(id) {
    alert(text.FUNCTION_SOON_AVAILABLE)
  }
  getInterventionToPlann(team, begin, end) {
    return this.init().get(`/core/intervention/planning?teamId=${team}&begin=${begin}&end=${end}`).then(d => d.data)
  }
  getPlanningEvent(team, begin, end) {
    return this.init().get(`/core/event/planning?teamId=${team}&start=${begin}&end=${end}`).then(d => d.data)
  }
  moveOperation(interventionId, operationId, interventiondestination) {
    return this.init().put(`/core/intervention/${interventionId}/operation/${operationId}?interventionTo=${interventiondestination}`).then(d => d.data)
  }
  subscribe(companyuser) {
    return this.init().post(`/auth/registration`, companyuser).then(d => d.data)
  }
  subscribeClient(companyuser) {
    return this.init().post(`/auth/clientRegistration`, companyuser).then(d => d.data)
  }
  getSiteOperation(id) {
    return this.init().get(`/core/site/${id}/operation`).then(d => d.data)
  }
  getTaken(id) {
    return this.init().get(`/core/supplyElement/taken/${id}`).then(d => d.data)
  }
  setMontant(id, montant) {
    return this.init().put(`/core/devis/${id}/montant?montant=${montant}`).then(d => d.data)

  }
  setAcompte(id, acompte) {
    return this.init().put(`/core/devis/${id}/acompte?acompte=${acompte}`).then(d => d.data)
  }
  createSector(sector) {
    return this.init().post(`/core/secteur/`, sector).then(d => d.data)

  }
  getPartition(id) {
    return this.init().get(`/core/intervention/${id}/partition`).then(d => d.data)

  }
  createUpdateEvent(event) {
    return this.init().post("/core/event", event).then(d => d.data)
  }
  deleteEvent(event) {
    return this.init().delete("/core/event", {data: event}).then(d => d.data)
  }
  getEvent(eventId) {
    return this.init().get(`/core/event/${eventId}`).then(d => d.data)
  }
  setEstimate(id, estimate) {
    return this.init().put(`/core/intervention/${id}/estimate?estimate=${estimate}`).then(d => d.data)
  }

*/



  /*
  * Quotation part
  */
  getFilterQuotations(params) {
    return this.init().get("/client/getFilterQuotation", {params: params ? params : {}})
        .then(respose => {
          return {
            quotations: respose.data.content.map(e => this.normalizeQuotation(e)),
            count: respose.data.totalElements
          }
        })
  }
  getFilterQuotationsBySite(params) {
    return this.init().get("/client/getFilterQuotationBySite", {params: params ? params : {}})
        .then(respose => {
          return {
            quotations: respose.data.content,
            count: respose.data.totalElements
          }
        })
  }
  getFilterQuotationsByElements(params) {
    return this.init().get("/client/getFilterQuotationByElements", {params: params ? params : {}})
        .then(respose => {
          return {
            quotations: respose.data.content,
            count: respose.data.totalElements
          }
        })
  }
  getQuotation(id) {
    return this.init().get(`/client/quotation/${id}`).then(r => r.data)
  }
  getPoiTypes(layerType) {
    return this.init().get(`/client/job/poiType?layerType=${layerType}`).then(resp => resp.data);

  }
}
