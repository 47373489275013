import IbatApi from "../../utils/IbatApi";
import Modal from "react-responsive-modal";
import React, {Fragment, useEffect, useState} from "react";
import CustomImage from "./CustomImage";
import PDFIcon from "../../images/pdf-icon.png"
import NewDoc from "../../images/newdoc.png"
import FileUploader from "./FileUploader";
import {text} from "./constants";
import {useSnackbar} from "notistack";
import {v4} from "uuid";
import "../style/DocumentLodaerStyle.css"

export default function DocumentLoader({name, type}) {
    const api = new IbatApi()
    const [doc, setDocument] = useState()
    const [b64] = useState()

    /*useEffect(()=>{
      api.getFile(name).then(r =>{
        setB64(r.b64)
      })
    })*/

    function openModal() {
        if (typeof(name) === "string"){
            api.getFile(name).then(resp => {
                setDocument(resp.url)
            })
        }
        else if (type === "quotation" || type === "bill") {
            api.getOdooQuotationPdf(type, name).then(r =>{
                setDocument("data:application/pdf;base64," + r)
            })
        }
        else {
            setDocument("data:application/pdf;base64,"+name.data)
        }
    }

    return (
        (typeof(name) === "string" || typeof(name) === "number") ?
            <div className={"document-loader flex-row"}>
                {
                    (typeof(name) === "number" || name.split(".").pop().toLowerCase() === "pdf") ?
                        <img alt={""} src={PDFIcon} onClick={openModal}/>
                        :
                        <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>
                }

                <Modal open={doc} onClose={() => setDocument(null)}>
                    {
                        (typeof(name) === "number" || name.split(".").pop().toLowerCase() === "pdf") ?
                            <embed className={"customModalPDF"} src={doc}/>
                            :
                            <CustomImage className={"caroussel full-page flex-column"} name={name} downloadable={true}/>
                    }
                </Modal>
            </div>
            :
            <div className={"document-loader flex-row"}>
                {
                    name.type.toLowerCase() === "pdf" ?
                        <img alt={""} src={PDFIcon} onClick={openModal}/>
                        :
                        <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>
                }
                <Modal open={doc} onClose={() => setDocument(null)}>
                    {
                        name.type.toLowerCase() === "pdf" ?
                            <embed className={"customModalPDF"} src={doc}/>
                            :
                            <CustomImage className={"customImageModal"} name={name} downloadable={true}/>
                    }
                </Modal>
            </div>
    )
}

export function DocumentLoaderList({name, addDoc, type, noHeader}) {
    const api = new IbatApi()
    const [state, setState] = useState(name ?? [])
    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {setState(name)}, [name])


    function handleFiles(files) {

        switch (addDoc.type) {
            case "ConstructionMaterial":
                api.sendMaterialDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionMachinery":
                api.sendMachineryDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionDocument":
                api.sendDocumentDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "AdminDocument":
                api.sendAdminDocumentDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            default:
                break
        }

    }

    return (
        <>
            {
                !noHeader &&
                <div className={"grid header flex-row"}>
                    <img alt={""} src={NewDoc}/>
                    <span>
            Documents liés à la fourniture
          </span>
                </div>
            }
            <div className={"documentLoaderList flex-row"}>
                {state?.map(n => <DocumentLoader key={v4()} name={n} type={type}/>)}
            </div>
            {addDoc && <FileUploader files={[]} onUpload={handleFiles} id={addDoc.id}/>}
        </>
    )
}
