import React, { useRef, useState} from "react"
import Row from "./row/Row";
import CriteriaIcon from "../images/button-filter.png";
import Checkbox from "../components/util/Checkbox";
import "./interventionsStyle.css"
import IconButton from "@material-ui/core/IconButton";
import FirstPage from "../images/page-first.png";
import PreviousPage from "../images/page-previous.png";
import LastPage from "../images/page-last.png";
import NextPage from "../images/page-next.png";
import { text } from "../components/util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import Loading from "../loading/Loading";

export default function Table(
    {
        list,
        loading,
        columns,
        pageNumber,
        pageSize,
        onGetData,
        onHandleColumns,
        onHandleFilter,
        onEndTyping,
        rowCount,
        onHandleChangeRowsPerPage,
        onHandleChangePage,
        tabName,
        children,
        setPageSize,
        clickOnRow
    }) {

    const [visible, setVisible] = useState(false)
    const [timer, setTimer] = useState(null)
    const [typing, setTyping] = useState(false)
    const [selectedRow, setSelectedRow] = useState("")
    const [scrollToken, setScrollToken] = useState(null)
    const ref=useRef(null)



    function handleChange(value) {
        clearTimeout(timer);

        onHandleFilter(value)

        let time = setTimeout(triggerChange, 600);
        setTimer(time)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            triggerChange();
        }
    }

    function triggerChange() {
        setTyping(!typing)
        onEndTyping(!typing);
    }


    function onHandleCriteria() {
        setVisible(!visible)
    }

    function nextPage() {
        let k=pageNumber+1
        if(k<Math.floor(rowCount/pageSize)) {
            onHandleChangePage(k)
        }
    }

    function lastPage() {
        let k =Math.floor(rowCount/pageSize)
        onHandleChangePage(k)
    }

    function previousPage() {
        let k=pageNumber-1
        if(k>=0) {
            onHandleChangePage(k)
        }
    }

    function firstPage() {
        onHandleChangePage(0)
    }
    function mouseEnter(e){
        let scrollToken = setInterval(function(){
            if(e.target.id === "right"){
                ref.current.scrollLeft+=5
                // Scroll down, e.g. by using scrollBy
            }else{
                ref.current.scrollLeft-=5
                // Scroll up, e.g. by using scrollBy
            }
        }, 3);
        setScrollToken(scrollToken)
    }

    function mouseLeave(){
        clearInterval(scrollToken);
    }

    return (
        <div className={"tabs-interventions"}>
            <div className={"table-wrapper"}>
                <div className={"table-scroll"} ref={ref}>
                    <div className={"scroll"} id={"right"} onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}/>
                    <div className={"scroll"} id={"left"} onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}/>

                    <table>

                        <thead>
                        <tr>
                            {columns.filter(d => d.isChecked).map((d) => {
                                return d.isFiltrable ?
                                    <th key={d.id}>
                                        <div className={"filter-group"}>
                                            <input className={"filter-input"} id={d.id}
                                                   placeholder={d.id} name={d.id} type={d.type}
                                                   onChange={handleChange}
                                                   onKeyDown={handleKeyDown}/>
                                            <label htmlFor={d.id}
                                                   className={"filter-label"}>{d.label}</label>
                                        </div>
                                    </th>
                                    :
                                    <th key={d.id}><label className={"label"}>{d.label}</label></th>
                            })}
                        </tr>
                        <tr>
                            <td colSpan={columns.length}>
                                <div className={"pager"}>
                                    <IconButton onClick={firstPage}> <img alt={"img"}
                                                                          src={FirstPage}/>
                                    </IconButton>
                                    <IconButton onClick={previousPage}> <img alt={"img"}
                                                                             src={PreviousPage}/>
                                    </IconButton>

                                    <p style={{margin: 3}}>{rowCount} {text.RESULTS} | {text.PAGE} {pageNumber + 1} {text.OF} {Math.floor(rowCount / pageSize) + 1} {setPageSize ? " | " + text.RESULTS_PER_PAGE + ":" : ""}</p>

                                    {setPageSize && <select id={"pageNumber"} value={pageSize}
                                                            onChange={onHandleChangeRowsPerPage}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>}

                                    <IconButton onClick={nextPage}> <img alt={"img"}
                                                                         src={NextPage}/>
                                    </IconButton>
                                    <IconButton onClick={lastPage}> <img alt={"img"}
                                                                         src={LastPage}/>
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        {loading ? <Loading length={columns.filter(d => d.isChecked).length}/> :
                            <tbody>
                            {list.length <= 0 &&
                                <div className={"no-result"}>{text.NO_RESULT}</div>}
                            {list.map((ligne) => {
                                return (<Row key={ligne.id} keyRow={ligne.id} onClick={() => {
                                    if (clickOnRow) {
                                        window.open(`/${tabName}/${ligne.id}`, "_blank")
                                    }
                                    setSelectedRow(ligne.id)
                                }} tableName={tabName}
                                             columns={columns} ligne={ligne} trigger={onGetData}
                                             selected={selectedRow === ligne.id}/>)
                            })}
                            <tr>
                                <td colSpan={columns.length}>
                                    <div className={"pager"}>
                                        <IconButton onClick={firstPage}> <img alt={"img"}
                                                                              src={FirstPage}/>
                                        </IconButton>
                                        <IconButton onClick={previousPage}> <img alt={"img"}
                                                                                 src={PreviousPage}/>
                                        </IconButton>
                                        <p style={{margin: 3}}>{rowCount} {text.RESULTS} | {text.PAGE} - {pageNumber + 1} {text.OF} {Math.floor(rowCount / pageSize) + 1} | {text.RESULTS_PER_PAGE} :</p>
                                        <select id={"pageNumber"} value={pageSize}
                                                onChange={onHandleChangeRowsPerPage}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>

                                        <IconButton onClick={nextPage}> <img alt={"img"}
                                                                             src={NextPage}/>
                                        </IconButton>
                                        <IconButton onClick={lastPage}> <img alt={"img"}
                                                                             src={LastPage}/>
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                        }


                    </table>


                </div>
            </div>

            <div className={visible ? "criteria-list-dropdown visible" : "criteria-list-dropdown"}>
                <Tooltip title={text.CRITERIA_LIST}><IconButton onClick={onHandleCriteria}><img
                    alt={"img"} className={"criteria-icon"} src={CriteriaIcon}/></IconButton>
                </Tooltip>
                <ul className={"criteria-list"}>
                    {columns.map((col, id) =>
                        <li key={id}><Checkbox className={"criteria"}
                                               handleCheckChieldElement={onHandleColumns} {...col}
                                               myKey={id}/></li>)}
                </ul>
            </div>

            <div className={"criteria-bar"}>
                {children}
            </div>


        </div>
    )
}
