import {text} from "../util/constants";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@mui/material";
import OpenFormIcon from "../../images/new-interv.png";
import QuotationsViewByQuotation from "./QuotationsViewByQuotation";
import QuotationsViewBySites from "./QuotationsViewBySites";
import QuotationsViewByElements from "./QuotationsViewByElements";

export default function Quotations({defaultFilter}) {
    const {enqueueSnackbar}=useSnackbar()

    let vars = {};
    let filters = window.location.search.substring(1).split("&").filter(f => f.length>0)
    for (const filterKey in filters) {
        let filter = filters[filterKey].split("=")
        vars[filter[0]] = filter[1]
    }
    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "name", label: text.NAME, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "devis", label: text.FROM_COMMANDE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "odooRef", label: text.REF_COMMANDE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "Interventions", label: text.FROM_INTERVENTION, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "facturationEntity", label: text.FACTURATION_ENTITY, isChecked: true, isFiltrable: false, type: "objectLink"},
        {id: "site", label: text.DELIVERY_ENTITY, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "dateSent", label: text.DATE_SENT, isChecked: true, isFiltrable: true, type: "objectLink"},
    ]
    const [state, setState] = useState(
        {
            viewMode: "byElements",
            columns: columns,
            quotationList: [],
            pageSize: 50,
            pageNumber: 0,
            count: 0,
            filters: {
                id: vars['id'] ?? "",
                name: vars['name'] ?? (defaultFilter??""),
                devis: vars['devis'] ?? "",
                odooRef: vars['odooRef'] ?? "",
                Interventions: vars['Interventions'] ?? "",
                site: vars['site'] ?? "",
                validityDate: vars['validityDate'] ?? "",
                status: vars['status'] ?? "",
            }
        }
    )
    const [typing,setTyping]=useState(false)
    const [loading,setLoading]=useState(false)
    const api = new IbatApi()

    useEffect(() => {
        setState({...state, quotationList: []})
        if (state.viewMode === "byQuotation"){
            getQuotations()
        }
        else if (state.viewMode === "bySite") {
            getQuotationsBySite()
        }
        else if (state.viewMode === "byElements") {
            getQuotationsByElements()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing,state.pageNumber,state.pageSize, state.viewMode])

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        vars[name] = value
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}, lastQueryAt: new Date()}))
    }
    const handleChangePage = (newPage) => {
        setState( ({...state, pageNumber: newPage}))
    };
    const handleChangeRowsPerPage = (event) => {
        setState( ({...state, pageSize: event.target.value, pageNumber: 0}))
    };
    function clearFilter() {
        setState({
            ...state, filters: {
                id: "",
                status: "",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort: "",
                maps: false
            }
        })
        setTyping(!typing)
    }
    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState( ({...state, columns: columns, filters: filter, mapping: false}))
    }

    function getQuotations() {
        setLoading(true)
        var sendedAt = new Date()
        api.getFilterQuotations({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
            .then(res => {
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState( ({...state, quotationList: res.quotations ?? [], count: res.count ?? 0}))
                }
            })
            .catch(e=> {
                // enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                enqueueSnackbar(e?.data?.error ?? text.SNACKBAR_ERROR, {variant: "error"})
            })
    }

    function getQuotationsBySite() {
        setLoading(true)
        var sendedAt = new Date()
        api.getFilterQuotationsBySite({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
            .then(res => {
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState( ({...state, quotationList: res.quotations ?? [], count: res.count ?? 0}))
                }
            })
            .catch(e=> {
                enqueueSnackbar(e?.data?.error ?? text.SNACKBAR_ERROR, {variant: "error"})
            })
    }

    function getQuotationsByElements() {
        setLoading(true)
        var sendedAt = new Date()
        api.getFilterQuotationsByElements({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
            .then(res => {
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState( ({...state, quotationList: res.quotations ?? [], count: res.count ?? 0}))
                }
            })
            .catch(e=> {
                enqueueSnackbar(e?.data?.error ?? text.SNACKBAR_ERROR, {variant: "error"})
            })
    }


    return (
        <div className={"page list-page"}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "row", height: "92vh"}}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "column", position: "sticky", bottom: "50%", transform: "translateY(50%)"}}>
                    <IconButton onClick={() => setState({...state, quotationList: [], viewMode: "byQuotation"})} ><img src={OpenFormIcon}/></IconButton>
                    <IconButton onClick={() => setState({...state, quotationList: [], viewMode: "bySite"})} ><img src={OpenFormIcon}/></IconButton>
                    <IconButton onClick={() => setState({...state, quotationList: [], viewMode: "byElements"})} ><img src={OpenFormIcon}/></IconButton>
                </div>
                {
                    state.viewMode === "byQuotation" &&
                    QuotationsViewByQuotation({loading, handleChangeRowsPerPage, handleChangePage, getQuotations, handleColumns, handleFilter, state, setTyping, clearFilter})
                }
                {
                    state.viewMode === "bySite" &&
                    <div style={{height: "100%", overflowY: "auto", width: "100%"}}>
                        <QuotationsViewBySites state={state}/>
                    </div>
                }
                {
                    state.viewMode === "byElements" &&
                    <div style={{height: "100%", overflowY: "auto", width: "100%"}}>
                        <QuotationsViewByElements state={state}/>
                    </div>
                }
        </div>
        </div>
    )
}